import React from 'react';
import loadable from '@loadable/component';
const Text = loadable(() => import('../containers/text'));
const AltText = loadable(() => import('../containers/alt-text'));
const FeatureText = loadable(() => import('../containers/feature-text'));
const Quote = loadable(() => import('../containers/quote'));
const Documents = loadable(() => import('../containers/documents'));
const Partners = loadable(() => import('../containers/partners'));
const MapGateway = loadable(() => import('../containers/map-gateway'));

const DZ = ({ containers }) => (
  <>
    {containers.map((container, index) => {
      const key = `${container.id}_${container.__typename}`;
      const nextContainerType =
        containers[index + 1] && containers[index + 1].__typename;
      switch (container.__typename) {
        case 'STRAPI_ComponentContainersAltText':
          return <AltText key={key} data={container} />;
        case 'STRAPI_ComponentContainersText':
          return (
            <Text
              key={key}
              data={container}
              nextContainerType={nextContainerType}
            />
          );
        case 'STRAPI_ComponentContainersFeatureText':
          return <FeatureText key={key} data={container} />;
        case 'STRAPI_ComponentContainersQuote':
          return <Quote key={key} data={container} />;
        case 'STRAPI_ComponentContainersDocuments':
          return <Documents key={key} data={container} index={index} />;
        case 'STRAPI_ComponentContainersPartners':
          return <Partners key={key} data={container} index={index} />;
        case 'STRAPI_ComponentContainersMapGateway':
          return <MapGateway key={key} data={container} />;
        default:
          return <p key={key}>{container.__typename}</p>;
      }
    })}
  </>
);

export default DZ;
