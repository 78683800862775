import React from 'react';
import { motion } from 'framer-motion';
import { InView } from 'react-intersection-observer';
import { parentVariants } from './variants';

const Cmp = React.forwardRef(({ useInView, inView, className }, ref) => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 329 375"
    ref={ref}
    variants={parentVariants}
    className={className}
    {...(useInView
      ? {
          initial: 'out',
          animate: inView ? 'in' : 'out',
        }
      : {})}
  >
    <motion.path
      fill="currentColor"
      tw="origin-bottom-left"
      variants={{
        out: { scale: 0 },
        in: {
          scale: 1,
        },
      }}
      d="M164.7 0C74.2 0 .9 73.3.9 163.8c0 35.4 11.2 68.1 30.3 94.8l-12 115.6 112.2-50.1c10.7 2.2 21.8 3.4 33.2 3.4 90.4 0 163.700102-73.3 163.700102-163.7C328.4 73.3 255.1 0 164.7 0z"
    />
  </motion.svg>
));

const Bubble = ({ className, useInView = true }) => {
  if (useInView) {
    return (
      <InView threshold={0.5}>
        {({ ref, inView }) => {
          return (
            <Cmp
              ref={ref}
              inView={inView}
              useInView={true}
              className={className}
            />
          );
        }}
      </InView>
    );
  }
  return <Cmp className={className} />;
};

export default Bubble;
