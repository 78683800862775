import React from 'react';
import { graphql } from 'gatsby';
import tw, { styled, css } from 'twin.macro';
import { getImage, GatsbyImage, withArtDirection } from 'gatsby-plugin-image';
import Bubble from '../svgs/bubble';
import Logo from '../svgs/logo';
import ArrowDownward from '../../images/icons/arrow_downward.inline.svg';
import { motion, animate } from 'framer-motion';
import { useRef } from 'react';
import InView from 'react-intersection-observer';

const mqs = {
  bg_1: '(max-aspect-ratio: 2/3)',
  bg_2: '(max-aspect-ratio: 16/10) and (min-width: 768px)',
  bg_3: '(min-aspect-ratio: 28/11)',
};

const HeroImageWrapper = styled.div`
  position: relative;
  padding-bottom: ${(6 / 13) * 100}%;

  @media ${mqs.bg_1} {
    padding-bottom: ${(3 / 2) * 100}%;
  }

  @media ${mqs.bg_2} {
    padding-bottom: ${(9 / 16) * 100}%;
  }

  @media ${mqs.bg_3} {
    padding-bottom: ${(3 / 8) * 100}%;
  }
`;

const HeroImage = styled(GatsbyImage)`
  ${tw`!absolute !inset-0`}
`;

const HomeHero = ({ data }) => {
  const images = withArtDirection(getImage(data.bg.localFile), [
    {
      media: mqs.bg_1,
      image: getImage(data.bg_1.localFile),
    },
    {
      media: mqs.bg_2,
      image: getImage(data.bg_2.localFile),
    },
    {
      media: mqs.bg_3,
      image: getImage(data.bg_3.localFile),
    },
  ]);

  const ref = useRef();

  const scrollY = () =>
    window.pageYOffset || document.documentElement.scrollTop;

  const offsetTop = (el) => {
    const { top } = el.getBoundingClientRect();

    return top + scrollY();
  };

  const scrollToNext = () => {
    const el = ref.current;
    if (el && el.nextSibling) {
      animate(scrollY(), offsetTop(el.nextSibling), {
        onUpdate: (value) => {
          window.scrollTo(0, value);
        },
      });
    }
  };

  return (
    <div ref={ref} tw="relative">
      <HeroImageWrapper>
        <HeroImage image={images} alt={data.bg.alternativeText} />
      </HeroImageWrapper>
      <div tw="absolute inset-0 flex items-center justify-center text-left">
        <InView threshold={0.7} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.div
              tw="relative"
              initial="out"
              animate={inView ? 'in' : 'out'}
              ref={ref}
              transition={{
                staggerChildren: 0.1,
                delayChildren: 0.8,
              }}
            >
              <Bubble
                tw="text-brand-purple-100 w-64 xl:w-96"
                useInView={false}
              />
              <motion.div
                css={[
                  tw`absolute inset-0 flex items-center justify-center flex-col w-full pl-8 pb-12`,
                  tw`xl:(pl-16 pb-16)`,
                ]}
                transition={{ staggerChildren: 0.2 }}
              >
                <motion.h1
                  tw="flex items-center justify-center w-full"
                  transition={{ staggerChildren: 0.8, delay: 0.6 }}
                >
                  <Logo
                    css={[
                      tw`flex-grow-0`,
                      css`
                        width: 70px;
                        height: 70px;
                        @media (min-width: 1280px) {
                          width: 120px;
                          height: 120px;
                        }
                      `,
                    ]}
                    useInView={false}
                  />
                  <motion.div
                    tw="flex-grow"
                    transition={{ staggerChildren: 0.1 }}
                  >
                    {data.title.split(' ').map((part) => (
                      <motion.span
                        key={part}
                        variants={{
                          in: { x: 0, opacity: 1 },
                          out: { x: 40, opacity: 0 },
                        }}
                        tw="uppercase font-light text-xl leading-none pl-1 block xl:text-3xl"
                      >
                        {part}
                      </motion.span>
                    ))}
                  </motion.div>
                </motion.h1>
                <motion.h2
                  css={[
                    tw`font-bold text-brand-green text-2xl leading-none pl-1 pr-4`,
                    tw`xl:(text-3xl leading-none pr-20)`,
                  ]}
                  variants={{
                    out: { opacity: 0, scale: 0 },
                    in: { opacity: 1, scale: 1 },
                  }}
                >
                  {data.subtitle}
                </motion.h2>
              </motion.div>
            </motion.div>
          )}
        </InView>
      </div>
      <div tw="absolute bottom-0 left-0 w-full pb-8 text-center">
        <motion.button
          tw="p-2 mx-auto border-brand-green border text-white rounded-full focus:outline-none"
          onClick={scrollToNext}
          animate={{
            y: 2,
            transition: {
              type: 'spring',
              damping: 0,
              bounce: 0.1,
              duration: 1,
            },
          }}
          title="Go to next section"
        >
          <ArrowDownward tw="w-8 h-8" />
        </motion.button>
      </div>
    </div>
  );
};

export const query = graphql`
  fragment HomeHero on STRAPI_ComponentContainersHomeHero {
    title
    subtitle
    bg: background {
      url
      alternativeText
      localFile {
        childImageSharp {
          gatsbyImageData(aspectRatio: 2.16666667, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
    bg_1: background {
      url
      alternativeText
      localFile {
        childImageSharp {
          gatsbyImageData(aspectRatio: 1.5, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
    bg_2: background {
      url
      alternativeText
      localFile {
        childImageSharp {
          gatsbyImageData(aspectRatio: 1.77777778, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
    bg_3: background {
      url
      alternativeText
      localFile {
        childImageSharp {
          gatsbyImageData(aspectRatio: 2.66666667, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  }
`;

export default HomeHero;
