import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/common/seo';
import HomeHero from '../components/molecules/home-hero';
import DynamicZone from '../components/organisms/dynamic-zone';

const HomePage = ({
  data: {
    strapi: { home },
  },
}) => (
  <>
    <Seo {...home.seo} />
    <HomeHero data={home.home_hero} />
    <DynamicZone containers={home.containers} />
  </>
);

export const query = graphql`
  {
    strapi {
      home {
        containers {
          ... on STRAPI_ComponentContainersAltText {
            __typename
            ...AltText
          }
          ... on STRAPI_ComponentContainersStats {
            id
            __typename
          }
          ... on STRAPI_ComponentContainersMapGateway {
            __typename
            ...MapGateway
          }
          ... on STRAPI_ComponentContainersText {
            id
            __typename
            ...Text
          }
          ... on STRAPI_ComponentContainersQuote {
            __typename
            ...Quote
          }
        }
        home_hero {
          ...HomeHero
        }
        seo {
          ...Seo
        }
      }
    }
  }
`;

export default HomePage;
